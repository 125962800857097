import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'lego',
    component: () => import(/* webpackChunkName: "lego" */ '../views/Lego.vue'),
  },
  {
    path: '/lego/:id',
    name: 'lego-set',
    component: () => import(/* webpackChunkName: "lego-set" */ '../views/LegoSet.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

/* router.beforeEach(async (to, from, next) => {
  next();
}); */

export default router;
